<template>
  <div class="accommodations-table">
    <!-- NEW ACCOMMODATION BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col v-if="showButton" cols="3" md="auto" class="mb-1 mb-md-0">
        <b-button variant="primary" block @click.prevent="newAccommodation">
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Nou allotjament") }}</span>
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          debounce="1000"
          :disabled="isBusy"
        />
      </b-col>
    </b-row>

    <!-- ACCOMMODATIONS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="fetchAccommodations"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: name -->
          <template #cell(name)="row">
            <b-accommodation :uuid="row.item.uuid" :name="row.item.name" />
          </template>

          <!-- Column: owner -->
          <template #cell(owner)="data">
            <div v-if="data.value">
              {{ data.value }}
            </div>
            <b-badge v-else pill>
              {{ $t("Sense contracte") }}
            </b-badge>
          </template>

          <!-- Column: hasAnyCleaningProvided -->
          <template #cell(hasAnyCleaningProvided)="data">
            <template v-if="data.value">
              <div class="d-flex">
                <b-badge
                  v-if="data.item.cleaningProvided"
                  v-b-tooltip.hover.top="
                    `Neteja: ${data.item.cleaningProvider}`
                  "
                  pill
                  variant="success"
                  class="mx-25"
                >
                  N
                </b-badge>
                <b-badge
                  v-if="data.item.householdClothesProvided"
                  v-b-tooltip.hover.top="
                    `Roba: ${data.item.householdClothesProvider}`
                  "
                  pill
                  variant="success"
                  class="mx-25"
                >
                  R
                </b-badge>
                <b-badge
                  v-if="data.item.householdClothesCleaningProvided"
                  v-b-tooltip.hover.top="
                    `Bugaderia: ${data.item.householdClothesCleaningProvider}`
                  "
                  pill
                  variant="success"
                  class="mx-25"
                >
                  B
                </b-badge>
              </div>
            </template>
            <b-badge v-else pill>
              {{ $t("Sense neteja") }}
            </b-badge>
          </template>

          <!-- Column: active -->
          <template #cell(active)="data">
            <b-badge v-if="data.value" pill variant="success">
              {{ $t("Actiu") }}
            </b-badge>
            <b-badge v-else pill variant="danger">
              {{ $t("Inactiu") }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!isBusy"
      v-if="accommodationsCount > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="itemsPerPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="itemsPerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
    BAccommodation,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "name",
      sortDesc: false,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      itemsPerPage: 50,
      filter: null,
      fields: [
        { key: "name", label: this.$t("Nom"), sortable: true },
        { key: "location", label: this.$t("Ubicació"), sortable: true },
        { key: "owner", label: this.$t("Propietari"), sortable: true },
        {
          key: "hasAnyCleaningProvided",
          label: this.$t("Neteja"),
          sortable: true,
        },
        { key: "active", label: this.$t("Estat"), sortable: true },
      ],
    };
  },
  computed: {
    accommodationsCount() {
      return this.$store.getters["accommodations/count"];
    },
  },
  watch: {
    isBusy(busy) {
      if (!busy) {
        this.$nextTick(() => {
          //! this needs to be done in the nextTick because the input
          //! can not be focused if it is disabled, and the input is
          //! disabled while the data is being fetched
          this.$refs.filterInput.focus();
        });
      }
    },
  },
  mounted() {
    this.$refs.filterInput.focus();
  },
  methods: {
    fetchAccommodations(ctx, callback) {
      this.$store
        .dispatch("accommodations/fetchFilteredAccommodations", {
          pagination: true,
          itemsPerPage: ctx.perPage,
          page: ctx.currentPage,
          searchText: ctx.filter || "",
          sort: {
            key: ctx.sortBy,
            order: ctx.sortDesc ? "desc" : "asc",
          },
        })
        .then((response) => {
          callback(this.mapTableAccommodationsArray(response));
          this.totalRows = this.accommodationsCount;
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );

      return null;
    },
    mapTableAccommodationsArray(accommodations) {
      if (!accommodations.length) return null;
      return accommodations.map((accommodation) => ({
        uuid: accommodation.uuid || "No definit",
        picture: accommodation.coverPicture?.thumbnailUrl || null,
        name: accommodation.name || "No definit",
        location: accommodation.location?.city || "No definit",
        owner: accommodation.owner?.fullName || null,
        hasAnyCleaningProvided: this.hasAnyCleaningService(accommodation),
        cleaningProvided: accommodation.contract?.cleaningProvided || false,
        cleaningProvider: this.getContractProvidersNames(
          accommodation.contract?.cleaningProviders
        ),
        householdClothesProvided:
          accommodation.contract?.householdClothesProvided || false,
        householdClothesProvider: this.getContractProvidersNames(
          accommodation.contract?.householdClothesProviders
        ),
        householdClothesCleaningProvided:
          accommodation.contract?.householdClothesCleaningProvided || false,
        householdClothesCleaningProvider: this.getContractProvidersNames(
          accommodation.contract?.householdClothesCleaningProviders
        ),
        active: accommodation.active,
      }));
    },
    hasAnyCleaningService(accommodation) {
      if (!accommodation?.contract) {
        return false;
      }

      return (
        accommodation.contract?.cleaningProvided ||
        accommodation.contract?.householdClothesProvided ||
        accommodation.contract?.householdClothesCleaningProvided
      );
    },
    getContractProvidersNames(contractProviders) {
      if (!contractProviders?.length) {
        return this.$t("No definit", this.$i18n.locale);
      }

      const providerNames = contractProviders
        .map((contractProvider) => {
          return contractProvider.provider?.fullName || null;
        })
        .filter((providerName) => !!providerName);

      return providerNames.join(", ");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    newAccommodation() {
      this.$router.push({
        name: "foravila-add-accommodation",
      });
    },
  },
};
</script>

<style lang="scss">
.accommodations-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
