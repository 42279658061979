<template>
  <b-card>
    <accommodations-table show-button show-search />
  </b-card>
</template>

<script>
import AccommodationsTable from "@/views/accommodations/list/components/AccommodationsTable.vue";
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
    AccommodationsTable,
  },
};
</script>
